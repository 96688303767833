
import 'swiper/css'
import 'swiper/css/pagination'
import Image from 'next/image'
import { Pagination, Autoplay } from 'swiper'
import { Swiper } from 'swiper/react'
import { ReactNode, useState } from 'react'

import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const imageDomain = publicRuntimeConfig.imageDomain

/**
 * @loop 是否循环播放
 * @pagination 是否展示Dot分页器
 * @slidesPerView 一行显示几个，如需要最后一个元素遮盖可加上小数点
 * @children ReactNode
 */
interface propsVoid {
  autoplay?: boolean;
  delay?: number;
  height?: number;
  hideArrows?: boolean;
  hoverShowArrows?: boolean;
  direction?: string;
  loop?: boolean;
  pagination?: boolean;
  slidesPerView: number;
  slidesPerGroup?: number;
  children: ReactNode;
  slideChange?: Function;
}

export default function SwiperSelf(props: propsVoid) {
  const [swiper, setSwiper]: any = useState(null);
  return (
    <div className={`swiperSelf ${props.hoverShowArrows ? 'hover-show-arrows' : ''}`}>
      <Swiper
        className='swiper-no-swiping'
        modules={[ Pagination, Autoplay ]}
        height={props.height || null}
        style={{ height: props.height ? props.height + 'px' : 'auto' }}
        loop={ props.loop ? true : false }
        direction={props.direction === 'vertical' ? 'vertical' : 'horizontal'}
        pagination={ props.pagination ? { clickable: true } : false }
        autoplay={ props.autoplay ? { delay: props.delay || 4000 } : false }
        slidesPerView={props.slidesPerView}
        slidesPerGroup={props.slidesPerGroup || parseInt(`${props.slidesPerView}`)}
        onSwiper={(swiper) => setSwiper(swiper)}
        onSlideChange={(event)=>{props.slideChange && props.slideChange(event.isBeginning,event.isEnd, event.activeIndex);
        }}
      >
        {props.children}
      </Swiper>
      {
        !props.hideArrows &&
        <>
          <div className="swiper-wrapper-prev" onClick={() => { swiper.slidePrev(); }}>
            <Image
              src={`${imageDomain}/arrow-left.png`}
              alt={'banner'}
              width={12}
              height={20}></Image>
          </div>
          <div className="swiper-wrapper-next" onClick={() => { swiper.slideNext(); }}>
            <Image
              src={`${imageDomain}/arrow-right.png`}
              alt={'banner'}
              width={12}
              height={20}></Image>
          </div>
        </>
      }
    </div>
  )
}